import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '490014c9-11dd-4672-890a-525e1a6b8843',
  clientToken: 'pubba1c7b3d0689b731e26ca59dc5738b2e',
  site: 'datadoghq.com',
  env: process.env.NODE_ENV,
  service: 'dmg-pro-corporate-website',
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
