import { createContext, useState } from 'react';

export const AppContext = createContext({});


export const AppContextProvider = ({ children }) => {
  const [context, setContext] = useState({ providerId: '' });
  return (
    <AppContext.Provider value={[context, setContext]}>
      {children}
    </AppContext.Provider>
  );
} 