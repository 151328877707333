import React, { Suspense, lazy } from "react";

import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { AppContextProvider } from "./context/AppContext";
import "./App.css";
import "./responsive.css";
import Header from "./components/Header/Header";

// const OurCompany = lazy(() => import("./pages/OurCompany"));
// const Technicians = lazy(() => import("./pages/Technicians"));
// const Providers = lazy(() => import("./pages/Providers"));
// const ContactUs = lazy(() => import("./pages/ContactUs"));
// const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
// const Services = lazy(() => import("./pages/Services"));
// const InnerService = lazy(() => import("./pages/InnerService"));
// const Terms = lazy(() => import("./pages/Terms"));
const Home = lazy(() => import("./pages/Home"));
const Footer = lazy(() => import("./components/Footer/Footer"));
class App extends React.Component {
  render() {
    return (
      <>
        <ScrollToTop /> {/* automatically scroll to top when router changes */}
        <div className="App">
          <AppContextProvider>
            <Header />
            <Suspense
              fallback={
                <div
                  className="spinner-border spinner-load"
                  role="status"
                ></div>
              }
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route exact path="/aboutUs" element={<Home />} />
                <Route exact path="/providers" element={<Home />} />
                <Route exact path="/technicians" element={<Home />} />
                <Route
                  exact
                  path="/services/:type"
                  element={<Home />}
                />
                <Route exact path="/services" element={<Home />} />
                <Route exact path="/contactus" element={<Home />} />
                <Route exact path="/terms-and-conditions" element={<Home />} />
                <Route
                  exact
                  path="/privacy-policy"
                  element={<Home />}
                />
              </Routes>
              <Footer />
            </Suspense>
          </AppContextProvider>
        </div>
      </>
    );
  }
}

export default App;
