import React, { useState } from "react";
import "./header.css";
import dmglogo from "../../assets/images/DMG_logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [state, setState] = useState({
    nav_opacity: 1,
    close_opacity: 0,
  });
  const openbtn = () => {
    // Change the state of the button
    setState({
      nav_opacity: !!state.nav_opacity ? 0 : 1,
      close_opacity: !!state.close_opacity ? 0 : 1,
    });
  };

  const closebtn = () => {
    // Change the state of the button
    setState({
      nav_opacity: !!state.nav_opacity ? 0 : 1,
      close_opacity: !!state.close_opacity ? 0 : 1,
    });
  };
  const onSignInClick = () => {
    const redirectPath = `https://login.dmgpro.com/?clientId=3648f4fe-1587-442a-a6d3-01212f2a08a0&redirectUri=https://controlcenter.dmgpro.com`;
    window.location.href = redirectPath;
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={dmglogo} alt="DMG Logo" width="105px" height="60px" />
          </Link>
          <div className="d-flex align-items-center">
            <div className="nav-item dropdown d-phone gap-1">
              <select className="p-0">
                <option defaultValue>US English</option>
                <option value="1">Spanish</option>
              </select>
              <button
                className="nav-item nav-link btn btn-outline-primary rounded-pill text-primary px-3"
                onClick={onSignInClick}
              >
                Sign In
              </button>
            </div>
            <button
              type="button"
              className="navbar-toggler"
              aria-label="hamburger-toggle-nav"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span
                style={{ opacity: state.nav_opacity }}
                className="navbar-toggler-icon"
                id="toggle_open"
                onClick={openbtn}
              ></span>
              <div
                onClick={closebtn}
                className="button_container"
                id="toggle_close"
                style={{ opacity: state.close_opacity }}
              >
                <span className="top"></span>
                <span className="bottom"></span>
              </div>
            </button>
          </div>
          <div
            className="collapse navbar-collapse flex-grow-0"
            id="navbarCollapse"
          >
            <div className="navbar-nav">
              <Link className="nav-item nav-link" to="/aboutus">
                {" "}
                Our Company
              </Link>
              <Link className="nav-item nav-link" to="/technicians">
                {" "}
                Technicians
              </Link>
              <Link className="nav-item nav-link" to="/providers">
                {" "}
                Providers
              </Link>
              <Link className="nav-item nav-link" to="/services">
                {" "}
                Services
              </Link>
              <div className="nav-item dropdown d-web">
                <select className="p-0">
                  <option defaultValue>US English</option>
                  <option value="1">Spanish</option>
                </select>
              </div>
              <button
                className="nav-item nav-link btn btn-outline-primary rounded-pill text-primary px-3"
                onClick={onSignInClick}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
